import { h, createContext } from 'preact';
import { useReducer } from 'preact/hooks';
import { initFormData as formData } from './initForm.constant';
import AppReducer from './AppReducer';

// TODO: REMOVE DEBUGGING MESSAGES

export const GlobalContext = createContext(formData);

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, formData);

  function updateModelProperty(val) {
    dispatch({
      type: 'UPDATE_MODEL_PROPERTY',
      payload: val
    });
  }

  return (
    <GlobalContext.Provider value={ { state, updateModelProperty } }>
      { children }
    </GlobalContext.Provider>
  );
}
