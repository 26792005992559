export function showSpinner() {
  document.querySelector('.modal-spinner').classList.add('active');
}

export function closeSpinner() {
  document.querySelector('.modal-spinner').classList.remove('active');
}

export function showConfirmationModal() {
  document.querySelector('#confirmation-modal-wrapper .modal').classList.add('show');
  document.querySelector('body').classList.add('.modal-open');
}
