export default (state, action) => {
  const merged = {
    ...state,
    ...action.payload
  };

  switch (action.type) {
    case 'UPDATE_MODEL_PROPERTY':
      return {
        ...merged
      }

    default:
      throw new Error('UNREGISTERED ACTION!');
  }
}
