import * as querystring from 'querystring';

export function queryStringParser(qs, state) {
  const cleanQs = qs.substring(1);

  try {
    if (qs.charAt(0) === '?') {
      return querystring.decode(cleanQs);
    } else {
      return {};
    }
  } catch (err) {
    console.log(err);
  }
}
