// TODO: refactor since this isn't CAKE

export async function cakePost(data) {
  const cakePostData = JSON.stringify(data);
  const cakePostEndpointUrl = 'https://api.auhsb.com/submit/'
  const postOptions = {
    method: 'POST',
    url: cakePostEndpointUrl,
    body: cakePostData,
    headers: {
      'Content-Type': 'application/json'
    }
  };

  return await fetch(cakePostEndpointUrl, postOptions)
    .then(res => {
      return res.json();
    });
}
