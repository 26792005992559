if (process.env.NODE_ENV === 'development') {
  // Must use require here as import statements are only allowed
  // to exist at the top of a file.
  require('preact/debug');
}

// TODO: SQUASH COMMITS TO OBFUSCATE THE FACT THIS WAS FROM A PREVIOUS IMPLEMENTATION

/* eslint-disable import/first */
import { h, render } from 'preact';
import { HashRouter, Switch, Route } from 'react-router-dom';

// PROVIDER
import { GlobalProvider } from './lib/GlobalState';
import { AccidentType } from './lib/components/accidentType.component';
import { IncidentDate } from './lib/components/incidentDate.component';
import { AtFault } from './lib/components/atFault.component';
import { CommercialVehicle } from './lib/components/commercialVehicle.component';
import { Injured } from './lib/components/injured.component';
import { MedicalTreatment } from './lib/components/medicalTreatment.component';
import { EstMedicalBills } from './lib/components/estMedicalBills.component';
import { RetainedAttorney } from './lib/components/retainedAttorney.component';
import { AccidentState } from './lib/components/accidentState.component';
import { Comments } from './lib/components/comments.component';
import { Contact } from './lib/components/contact.component';

// COMPONENTS
// types of components - buttons, text/number/email inputs, select dropdowns

import { initializeModals } from './lib/modals';
import { setCopyYear } from './lib/setCopyYear';
// import { initializeConfirmationModal } from './lib/confirmationModal';

// Initialize Modals
initializeModals();

const appRoot = document.getElementById('app');

// TODO:  Loop over array and make App element less verbose
// [{url, component}]

const App = (
  <HashRouter>
    <Switch location={location}>
      <GlobalProvider>
        <Route exact path={'/'}>
          <AccidentType />
        </Route>
        <Route exact path={'/incident-date'}>
          <IncidentDate />
        </Route>
        <Route exact path={'/at-fault'}>
          <AtFault />
        </Route>
        <Route exact path={'/commercial-vehicle'}>
          <CommercialVehicle />
        </Route>
        <Route exact path={'/injured'}>
          <Injured />
        </Route>
        <Route exact path={'/medical-treatment'}>
          <MedicalTreatment />
        </Route>
        <Route exact path={'/est-medical-bills'}>
          <EstMedicalBills />
        </Route>
        <Route exact path={'/retained-attorney'}>
          <RetainedAttorney />
        </Route>
        <Route exact path={'/accident-state'}>
          <AccidentState />
        </Route>
        <Route exact path={'/comments'}>
          <Comments />
        </Route>
        <Route exact path={'/contact'}>
          <Contact />
        </Route>
      </GlobalProvider>
    </Switch>
  </HashRouter>
);

render(App, appRoot);
